import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const RecentPost = ({ post }) => (
  <Link to={post.fields.slug} className="recent-post clearfix">
    <div className="recent-post-img">
      <Img
        fluid={post.frontmatter.image.recentPostImage.fluid}
        alt={post.frontmatter.title}
      />
    </div>
    <div className="recent-post-content">
      <h2 className="entry-title">{post.frontmatter.title}</h2>
      <span className="posted-on">
        <time
          className="entry-date published updated"
          dateTime={post.frontmatter.date}
        >
          {post.frontmatter.formattedDate}
        </time>
      </span>
    </div>
  </Link>
)

const RecentPosts = () => {
  const data = useStaticQuery(graphql`
    fragment RecentPostFragment on MarkdownRemark {
      id
      frontmatter {
        title
        date
        formattedDate: date(formatString: "DD MMMM, YYYY")
        image {
          recentPostImage: childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
    query {
      recentPosts: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        skip: 0
        limit: 8
      ) {
        nodes {
          ...RecentPostFragment
        }
      }
    }
  `)

  return (
    <section className="widget c-widget widget_subscribe_recent">
      <h2 className="widget-title">All Recent Posts</h2>
      <ul>
        {data.recentPosts.nodes.map(post => (
          <li key={post.id}>
            <RecentPost post={post} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default RecentPosts
