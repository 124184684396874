import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const HomeSlider = ({ selectedCategory }) => {
  const data = useStaticQuery(
    graphql`
      fragment CategoryPreview on MarkdownRemark {
        id
        frontmatter {
          title
          category
          image {
            childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
      query {
        sights: allMarkdownRemark(
          limit: 1
          filter: { frontmatter: { category: { eq: "sights" } } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          nodes {
            ...CategoryPreview
          }
        }
        sounds: allMarkdownRemark(
          limit: 1
          filter: { frontmatter: { category: { eq: "sounds" } } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          nodes {
            ...CategoryPreview
          }
        }
        stories: allMarkdownRemark(
          limit: 1
          filter: { frontmatter: { category: { eq: "stories" } } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          nodes {
            ...CategoryPreview
          }
        }
        picks: allMarkdownRemark(
          limit: 1
          filter: { frontmatter: { category: { eq: "picks" } } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          nodes {
            ...CategoryPreview
          }
        }
      }
    `
  )
  const categoryPreviews = [
    data.sounds.nodes[0],
    data.sights.nodes[0],
    data.stories.nodes[0],
    data.picks.nodes[0],
  ]

  let selectedCategoryPreview = categoryPreviews.find(
    preview => preview.frontmatter.category === selectedCategory
  )
  if (!selectedCategoryPreview) {
    selectedCategoryPreview = categoryPreviews[0]
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12 no-padd ">
          <Link to={selectedCategoryPreview.fields.slug}>
            <div className="home-banner-container">
              {categoryPreviews.map(preview => (
                <div
                  className={`home-banner ${
                    preview === selectedCategoryPreview ? "active" : ""
                  }`}
                  key={preview.id}
                >
                  <Img
                    fluid={preview.frontmatter.image.childImageSharp.fluid}
                    className="img-responsive"
                    alt={preview.frontmatter.title}
                  />
                </div>
              ))}
            </div>
          </Link>
        </div>
      </div>

      <div className="row">
        {categoryPreviews.map((preview, index) => (
          <div key={preview.id} className="col-xs-12 col-sm-6 col-md-3 no-padd">
            <section
              className={`c-featured-post featured-tab style-2 ${
                preview.frontmatter.category === selectedCategory
                  ? "active"
                  : ""
              } ${index === 1 ? "brdr-xs" : ""} ${index > 1 ? "brdr-sm" : ""}`}
            >
              <h4 className="featured-post-title">
                <Link to={`/${preview.frontmatter.category}`}>
                  {preview.frontmatter.category}
                </Link>
              </h4>
              <h6 className="featured-post-subtitle">
                <Link to={preview.fields.slug}>
                  {preview.frontmatter.title}
                </Link>
              </h6>
            </section>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HomeSlider
