import React from "react"
import { Link } from "gatsby"
import RecentPosts from "../components/recentposts"

const Sidebar = () => (
  <div className="col-xs-12 col-sm-12 col-md-4 marg-xs-t40 marg-lg-t70">
    <aside className="widget-area c-sidebar">
      {/* <!-- ADVERTISEMENT --> */}
      {/* <section className="widget widget_text c-widget widget_about">
        <h2 className="widget-title">MEET OUR ADVENTURERS</h2>
        <div className="textwidget">
          <div className="wp-caption about-img-wrap">
            <Link to="/">
              <img
                src="img/about-1.jpg"
                className="img-responsive center-block"
                alt="Banner"
              />
            </Link>
          </div>
          <p>Dreamin' of traveling since I am a tiny girl!</p>
          <div className="about-signature">Beth Packer</div>
        </div>
      </section> */}

      {/* <!-- RECENT POSTS --> */}
      <RecentPosts />
    </aside>
  </div>
)

export default Sidebar
