import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BigPostPreview from "../components/bigpostpreview"
import SmallPostPreview, {
  layoutSmallPosts,
} from "../components/smallpostpreview"
import HomeSlider from "../components/homeslider"
import Sidebar from "../components/sidebar"
import SEO from "../components/seo"

export const query = graphql`
  query($category: String) {
    mainPreview: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          ...BigPostPreviewFragment
        }
      }
    }
    smallPreviews: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: 1
      limit: 20
    ) {
      edges {
        node {
          ...SmallPostPreviewFragment
        }
      }
    }
  }
`

const CategoryPage = ({ pageContext, data }) => {
  const { category } = pageContext
  const smallPreviews = data.smallPreviews.edges.map(x => x.node)

  return (
    <Layout>
      <SEO title={category} />
      <div className="content-area">
        <main className="site-main">
          <HomeSlider selectedCategory={category} />

          <div className="bg-c-1 padd-xs-b60 padd-lg-b120">
            <div className="container padd-only-sm">
              <div className="row">
                {/* <!-- BLOG --> */}
                <div className="col-xs-12 col-sm-12 col-md-8 marg-xs-t40 marg-lg-t70 no-padd">
                  <div className="container-fluid padd-only-sm">
                    <div className="row">
                      <div className="col-xs-12 padd-15">
                        <BigPostPreview data={data.mainPreview.edges[0].node} />
                      </div>
                    </div>

                    {layoutSmallPosts(smallPreviews)}

                    {/* <!-- NAVIGATION --> */}
                    {/* <nav className="c-pagination">
                      <div className="nav-links clearfix">
                        <a
                          className="next page-numbers c-pagination-link left"
                          href="#"
                        >
                          <i className="fa fa-angle-double-left" />
                          OLDER POSTS
                        </a>
                        <a
                          className="previous page-numbers c-pagination-link right"
                          href="#"
                        >
                          PREVIOUS POSTS
                          <i className="fa fa-angle-double-right" />
                        </a>
                      </div>
                    </nav> */}
                  </div>
                </div>

                <Sidebar />
              </div>
            </div>
          </div>
        </main>
        {/* <!-- main --> */}
      </div>
      {/* <!-- primary --> */}
    </Layout>
  )
}

export default CategoryPage
