import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ShareWidget from "./sharewidget"
import ShareCounter from "./sharecounter"

const BigPostPreview = ({ data }) => {
  return (
    <article className="c-post c-big-post">
      <header className="entry-header">
        <h6 className="entry-category">
          <Link to={`/${data.frontmatter.category}`}>
            {data.frontmatter.category}
          </Link>
        </h6>
        <h2 className="entry-title">
          <Link to={data.fields.slug}>{data.frontmatter.title}</Link>
        </h2>
      </header>
      <div className="post-img-link-wrapper-featured">
        <Link to={data.fields.slug} className="post-img-link">
          {data.frontmatter.image.bigPreviewImage.fluid && (
            <Img fluid={data.frontmatter.image.bigPreviewImage.fluid} alt="" />
          )}
        </Link>
      </div>

      <div className="entry-content">
        <div dangerouslySetInnerHTML={{ __html: data.excerpt }} />
        <p className="more-wrap">
          <Link to={data.fields.slug} className="more-link">
            continue reading
          </Link>
        </p>
      </div>

      <footer className="entry-footer clearfix">
        <div className="entry-footer-bottom clearfix">
          <span className="posted-on footer-elems">
            <a href="#" rel="bookmark">
              <time
                className="entry-date published updated"
                dateTime={data.frontmatter.date}
              >
                {data.frontmatter.bigDateFormat}
              </time>
            </a>
          </span>
          <div className="share-links footer-elems">
            <i>SHARE</i>
            <ShareWidget slug={data.fields.slug} />
          </div>
          <span className="comments-link footer-elems">
            <ShareCounter slug={data.fields.slug} />
          </span>
        </div>
      </footer>
    </article>
  )
}

export default BigPostPreview

export const query = graphql`
  fragment BigPostPreviewFragment on MarkdownRemark {
    id
    frontmatter {
      title
      category
      date
      bigDateFormat: date(formatString: "DD MMMM, YYYY")
      image {
        bigPreviewImage: childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tags
    }
    excerpt
    fields {
      slug
    }
  }
`
